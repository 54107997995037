<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Terminal Deployments - DSR</h1>
      <!-- <div class="bg-blue-100 p-5">
            <p>
              Please note that all downloads are in .txt formats. You will need to
              change it to .csv format after downloading it. Merci.
            </p>
          </div> -->
      <div class="flex justify-end items-end my-5">
        <button
          @click="downloadCSV"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-600 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Download CSV
        </button>
      </div>

      <div class="flex items-center my-5">
        <input
          type="text"
          class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
          name=""
          placeholder="Search by Business Name, and Email"
          id=""
          v-model="search"
        />
        <button
          @click="handleSearch"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Search
        </button>
        <button
          @click="clearFilter"
          class="block uppercase shadow ml-2 text-sm bg-red-900 text-white hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Reset
        </button>
      </div>
      <div class="flex bg-blue-50 p-5 mt-10">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 h-10 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startDate"
          />
        </div>
        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 h-10 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.endDate"
          />
        </div>
        <div class="flex mt-5">
          <button
            @click="fetchDatedRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs h-10 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-3 rounded"
          >
            Reset
          </button>
        </div>
      </div>

      <div class="mt-5" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="26"
                style="background: #dee6f2"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                POS Terminal serial
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal type
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Generated activation code
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Merchant address
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                State
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                LGA
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Storefront image
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-xs"
              v-for="(terminal, i) in filterTerminalDeployments"
              :key="i"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.serial_no || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.TerminalType || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.activation_code || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.business_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.store_address || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.state || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminal.lga || "N/A" }}
              </td>
              <td class="border px-8 py-3 text-xs">
                <button
                  class="btn"
                  @click="loadImage(terminal.store_image_url)"
                >
                  View image
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="filterTerminalDeployments.length > 99"
        >
          Next &raquo;
        </button>
      </div>
      <div
        v-if="promptModal"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-full md:w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4"
        >
          <img :src="imgUrl" class="d-block mx-auto" style="width: 200px" />
          <div class="block">
            <button
              @click="promptModal = false"
              class="block uppercase mx-auto hover:bg-transparent hover:text-red-600 bg-transparent focus:shadow-outline focus:outline-none text-red-600 text-xs font-bold py-2 px-4 rounded"
              style="width: inherit"
            >
              Cancel
            </button>
            <i
              @click="promptModal = false"
              class="bi bi-x text-white icon-cancel cursor-pointer"
              style="font-size: 3rem"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Modal from "./Modal.vue";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      current: "NEW",
      currentPage: 1,
      source: "dsr",
      searchActive: false,
      dateFilterActive: false,
      isDisabled: true,
      promptModal: false,
      imgUrl: "",
    };
  },
  components: {
    Modal,
    Loading,
  },
  computed: {
    ...mapGetters(["GET_TERMINAL_DEPLOYMENT"]),
    filterTerminalDeployments() {
      return this.GET_TERMINAL_DEPLOYMENT.data !== null
        ? this.GET_TERMINAL_DEPLOYMENT.data
        : [];
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    // this.isLoading = true;
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 1)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchRecords();
    console.log(this.dateData.endDate);
  },
  methods: {
    ...mapActions(["FETCH_TERMINAL_DEPLOYMENTS"]),
    loadImage(url) {
      this.promptModal = true;
      this.imgUrl = url;
      console.log(this.imgUrl);
    },
    async fetchRecords() {
      this.isLoading = true;
      this.currentPage = 1;
      let payload = {
        page: this.currentPage,
        source: this.source,
        date: this.dateData,
        search: this.search,
        searchActive: this.searchActive,
        dateFilterActive: this.dateFilterActive,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_TERMINAL_DEPLOYMENTS",
          payload
        );
        if (res.status == true) {
          this.isLoading = false;
          if (res.data.data.length < 1) {
            alert(
              "Sorry, we couldn't load the file because an error occured. "
            );
          }
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async prevPage() {
      this.isLoading = true;
      this.currentPage = 1;
      let currPage = this.currentPage;
      let payload = {
        page: currPage - 1,
        source: this.source,
        date: this.dateData,
        search: this.search,
        searchActive: this.searchActive,
        dateFilterActive: this.dateFilterActive,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_TERMINAL_DEPLOYMENTS",
          payload
        );
        if (res.status == true) {
          this.isLoading = false;
          this.currentPage -= 1;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async nextPage() {
      this.isLoading = true;
      this.currentPage = 1;
      let currPage = this.currentPage;
      let payload = {
        page: currPage + 1,
        source: this.source,
        date: this.dateData,
        search: this.search,
        searchActive: this.searchActive,
        dateFilterActive: this.dateFilterActive,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_TERMINAL_DEPLOYMENTS",
          payload
        );
        if (res.status == true) {
          this.isLoading = false;
          this.currentPage += 1;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },

    async handleSearch() {
      this.isLoading = true;
      this.currentPage = 1;
      this.searchActive = true;
      let payload = {
        page: this.currentPage,
        source: this.source,
        date: this.dateData,
        search: this.search,
        searchActive: this.searchActive,
        dateFilterActive: this.dateFilterActive,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_TERMINAL_DEPLOYMENTS",
          payload
        );
        if (res.status == true) {
          this.isLoading = false;
          if (res.data.data.length < 1) {
            alert(
              "Sorry, we couldn't load the file because an error occured. "
            );
          }
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async fetchDatedRecords() {
      this.currentPage = 1;
      if (
        this.dateData.startDate > this.dateData.endDate ||
        this.dateData.startDate === this.dateData.endDate
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;
        this.currentPage = 1;
        this.dateFilterActive = true;
        let payload = {
          page: this.currentPage,
          source: this.source,
          date: this.dateData,
          search: this.search,
          searchActive: this.searchActive,
          dateFilterActive: this.dateFilterActive,
        };
        try {
          let res = await this.$store.dispatch(
            "FETCH_TERMINAL_DEPLOYMENTS",
            payload
          );
          if (res.status == true) {
            this.isLoading = false;
            if (res.data.data.length < 1) {
              alert(
                "Sorry, we couldn't load the file because an error occured. "
              );
            }
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {
          this.isLoading = false;
        }
      }
    },
    async downloadCSV() {
      this.isLoading = true;
      this.currentPage = 1;
      let payload = {
        source: this.source,
        date: this.dateData,
        search: this.search,
        searchActive: this.searchActive,
        dateFilterActive: this.dateFilterActive,
      };
      try {
        let res = await this.$store.dispatch(
          "DOWNLOAD_TERMINAL_DEPLOYMENTS",
          payload
        );
        if (res.status == true) {
          this.isLoading = false;
          window.open(res.data, "_blank");
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async clearFilter() {
      this.searchActive = false;
      this.search = "";
      this.fetchRecords();
    },
    async clearDateFilter() {
      this.dateFilterActive = false;
      this.currentPage = 1;
      this.resetDate();
      this.fetchRecords();
    },
    resetDate() {
      const d = new Date();
      this.dateData = {
        startDate: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
        endDate: new Date().toJSON().split("T")[0],
        startTime: "00:00",
        endTime: this.moment(d).format("HH:mm"),
      };
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
